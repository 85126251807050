import React, {useState} from 'react';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

function AspectCropper({ src }) {
    const [crop, setCrop] = useState()

    return (
        <div>
             <ReactCrop crop={crop} onChange={c => setCrop(c)}>
      <img src={src} />
    </ReactCrop>
        </div>
    );
}

export default AspectCropper;