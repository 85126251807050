import React, { useState, useEffect } from 'react';
import { Stack, Box, Card, Link, TextField, Typography, Button, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import CategoryItem from './CategoryItem';
import useStore from '../store';

function Sidebar({categories, setAllCategoryResp}) {
  const [categoryKeyword, setCategoryKeyword] = useState('');
  const [filteredArray, setFilteredArray] =useState(categories);

  useEffect(() =>{
    if (categoryKeyword != ''){
      setFilteredArray(categories.filter((category) => category.includes(categoryKeyword)));
    }else{
      setFilteredArray(categories);
    }
  }, [categoryKeyword, categories])

  const filterCategory = (e)=>{
    setCategoryKeyword(e.target.value);
    

  }

  return (
    <>

      <Stack sx={{ width: '25%', border: 1, borderRadius:0.1, background:'#ffff', borderColor: '#dedede', m:3, mr:6, p:2, maxHeight:600, overflow:'scroll'  }}>
       
        <TextField id="outlined-basic" label="Search Category" onChange={filterCategory} variant="outlined" />
        <List>
          {
           filteredArray?.map((category, i) => (
              <ListItem >
              <CategoryItem category={category} setAllCategoryResp={setAllCategoryResp} />
            </ListItem>
            ))
           }

        </List>

      </Stack>

    </>


  );
}

export default Sidebar;