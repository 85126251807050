import React from "react";
import {
  createSelectable,
  SelectableGroup,
  SelectAll,
  DeselectAll
} from "react-selectable-fast";
import { Stack, Box, Card, Divider, TextField, Typography, Grid, Button, ListItemText, useMediaQuery } from '@mui/material';
import useStore from "../store";
import Item from "./Item";


const SelectableComponent = createSelectable(Item);

const ItemGroup = (props) => {
  const imgs = useStore(state => state.imageDetails);
  const btnDisabled = useStore(state => state.btnDisabled);
  return (
    <div>
       <Stack direction="row" spacing={2} justifyContent="flex-start" sx={{m:2 }}>
      <SelectAll className="selectable-button">
      <Button variant="contained" disabled={btnDisabled} sx ={{backgroundColor: '#4291de'}}>Select All files</Button>
      </SelectAll>
      <DeselectAll className="selectable-button">
      <Button variant="contained " disabled={btnDisabled} sx ={{backgroundColor: btnDisabled ? '#e5e8eb' :  '#4291de' , color: '#ffff'}}  >Clear Selection</Button>
      </DeselectAll>
      </Stack>
      <Divider sx={{mt:2, mb:2}} />
      <Grid container spacing={2}>
        {
         
            Object.keys(imgs).map((img, i) => (
              <SelectableComponent key={i} imgSrc={img} initialCropped={imgs[img]?.imgDimension}  />
            ))
        }
    
      </Grid>
    </div>
  );
};

export default ItemGroup;
