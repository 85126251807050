import React, { useState, useEffect} from "react";
//import { createSelectable } from "react-selectable-fast";
import { Stack, Box, AppBar, Toolbar, TextField, Typography, Button, IconButton, Dialog, CircularProgress, useMediaQuery } from '@mui/material';
import closeFill from '@iconify/icons-eva/close-fill';
import editFill from '@iconify/icons-eva/edit-fill';
import { Icon } from '@iconify/react';
import useStore from "../store";
import ImageCropper from './ImageCropper';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
//import { canvasPreview } from './canvasPreview'

import AspectCropper from './AspectCropper';
import FreeCropper from "./FreeCropper";



const Item = ({ selectableRef, isSelected, isSelecting, imgSrc, initialCropped }) => {
  const background = isSelected ? "red" : "white";
  const background2 = isSelecting ? "orange" : "";
  const [isEdited, setIsEdited] = React.useState(false); 
  const [croppedPercent, setCroppedPercent] = useState(null);
  const [open, setOpen] = React.useState(false);
  const updateSearchresults = useStore(state => state.updateSearchresults);
  const updateImageSelection = useStore(state => state.updateImageSelection);


  useEffect(()=>{
    updateImageSelection({imgSrc,isSelected });
  }, [isSelected])

  

  const updateCroppedPercent = (croppedValue) => {
    setCroppedPercent(croppedValue);
    console.log("updated crop");
  };

  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const closeDialogue = () =>{
    setOpen(false);
  }

  const restoreImage = () =>{
    setCroppedPercent(null);
    const imgUpdateParams = {
      imgSrc: imgSrc,
      croppedArea: null
    };
    updateSearchresults(imgUpdateParams);
  }

  const handleClose = () => {
    setOpen(false);
    const imgUpdateParams = {
      imgSrc: imgSrc,
      croppedArea: croppedPercent
    };
    updateSearchresults(imgUpdateParams);
  };

  console.log(initialCropped);
  return (
    <>
    <Stack>
    <div 
      ref={selectableRef}
    >  <Box component="img" src={imgSrc}  sx={{m:1, position: 'relative', width:200, height: 200, border: isSelecting ? 3 : 0, border: isSelected ? 2 : 0}}></Box>
    {croppedPercent && ( <IconButton
              edge="start"
              color="inherit"
              onClick={handleClickOpen}
              aria-label="close"
              sx= {{position: "absolute",ml:1, mt: -5}}
            >
            
              <Icon icon="charm:circle-tick" />
            </IconButton>)  }
    
    </div>
      <Button
              fullWidth
              
              variant="contained"
              edge="start"
              color="primary"
              onClick={handleClickOpen}
              aria-label="close"
              sx= {{mr:2}}
            >
              EDIT
            
            </Button>
            </Stack>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        // TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeDialogue}
              aria-label="close"
            >
              <Icon icon={closeFill} />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Crop Image
            </Typography>
            <Button autoFocus color="inherit" onClick={restoreImage}>
              Cancel
            </Button>
            <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        {/* <ImageCropper cropImg={imgSrc} setCroppedPercent={setCroppedPercent}/> */}
        <FreeCropper imgParam={imgSrc} updateCroppedPercent={updateCroppedPercent} croppedValue={initialCropped} restoreImage={croppedPercent}></FreeCropper>
      </Dialog>
     </>
  );
};

export default Item;
