
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';

import Sidebar from './components/Sidebar';
import SearchImage from './components/SearchImage';
import { Stack, Box, Tab, Tabs, TextField, Typography, Button, ListItem, FormHelperText, LinearProgress  } from '@mui/material';
import useStore from './store';
import { useCallback } from 'react';
import UploadMultiFile from './components/UploadMultiFile';
import { Form, FormikProvider, useFormik } from 'formik';
import axios from 'axios';
import { useSnackbar } from "notistack";
import { Icon } from '@iconify/react';
import closeFill from "@iconify/icons-eva/close-fill";
import MIconButton from './components/MIconButton';
import LoadingScreen from './components/LoadingScreen';




import FreeCropper from './components/FreeCropper';

function TabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Home(props) {
  // const [label, setLabel] = useStore('');
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [serverUrl, setServerUrl] = React.useState(process.env.REACT_APP_baseURL);
  const activeCategory = useStore(state => state.activeCategory);
  const setActiveCategory = useStore(state => state.setActiveCategory);
  const addCategory = useStore(state => state.addCategory);
  const imageDetails = useStore(state => state.imageDetails);
  const resetImageDetails = useStore(state => state.resetImageDetails);
 // const imageDetails = useStore(state => state.imageDetails);
  const [allCategoryResp, setAllCategoryResp] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [tab0, setTab0Color] = useState('#ffff') ;
  const [tab1, setTab1Color] = useState('#f4f4f4') ;  
  const selectedColor = '#ffff' ;
  const unSelectedColor = '#f4f4f4' ;
  const selectedRadius = '15px 15px 0px 0px' ;
  const btnDisabled = useStore(state => state.btnDisabled);
  const setBtnState = useStore(state => state.setBtnState);



  const NewProductSchema = Yup.object().shape({

    images: Yup.array().min(1, 'Images is required'),
  });

  const getCategories = async () => {
    let tempCategories = [];
    try {
      console.log(serverUrl);
      fetch(serverUrl + 'getCategory', {
        method: 'GET',
      }).then((response) => {
        console.log(response);
        response.json().then((body) => {
          console.log(body);
          //setCategoryResp(body);
          Object.keys(body).map((category, i) => {
            console.log(category);
            tempCategories.push(category + ' (' + body[category] + ')')
          });
          console.log(tempCategories);
          setAllCategoryResp(tempCategories)
        });
      })
      

    } catch (error) {
      console.error(error);

    }
  };

  const  postCategory = async () => {
    let tempCategories = [];
    try {
        const serverUrl = process.env.REACT_APP_baseURL;

        let formData = new FormData();
        formData.append("keywords", activeCategory);
        console.log(formData);
        let res = await axios({
          method: "POST",
          url: serverUrl + "postCategory",
          data: {
            'keywords': activeCategory,
          },
          headers: {
            "Content-Type": "application/json",
             'keywords': activeCategory,
            
          }
        });

        if( typeof res?.data === 'string'){
          alert(res?.data)
        }else{
        console.log(res);
        const categoriesResponse = res?.data;
        
        Object.keys(categoriesResponse).map((category, i) => {
          console.log(category);
          tempCategories.push(category + ' (' + categoriesResponse[category] + ')')
        });

        setAllCategoryResp(tempCategories)
     
      }
    } catch (error) {
      console.error(error);
      
    }
  };

  useEffect(() => {
    getCategories();
  }, [])

  const setCategory = (e) => {
    setActiveCategory(e.target.value);
  };

  function findCategoryElement(array, category) {
    return array?.find((element) => {
      return element === category;
    })
  }

  const addLocalCategory = (e) => {
    console.log(activeCategory);
    if(!findCategoryElement(allCategoryResp, activeCategory)){
      postCategory();
      setActiveCategory('');
    }else{
      window.alert("Category Already Exists");
    }
  };
  const resumeTraining = async () => {
    try {
      enqueueSnackbar("Training started at cloud", {
        variant: "success",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
      let res = await axios({
        method: "POST",
        url: serverUrl + "resumeTraining",
        headers: {'Access-Control-Allow-Origin': '*'},
      });
      console.log(res);
     
      } catch (error) {
    console.error(error);
  
  }
}
  const  uploadScrappedImages = async () => {
   
    try {
      if (activeCategory === "" ){
        window.alert("Select Category against which images will get uploaded");
        return;
      }
      setLoading(true);
      Object.keys(imageDetails).forEach((key)=> {
        if (imageDetails[key]?.isActive === false) {
          delete imageDetails[key];
        }
      });
      //const filteredImages = imageDetails.filter((imageDetail)=> imageDetail.isActive.isSelected === true);
      console.log(imageDetails);

        let res = await axios({
          method: "POST",
          url: serverUrl + "uploadScrappedImages",
          data: {
            'imageDetails': imageDetails,
          },
          headers: {
            "Content-Type": "application/json",
             'imageDetails': JSON.stringify(imageDetails),
             'category': activeCategory
          }
        });
        setLoading(false);
        console.log(res);
        resetImageDetails();
        setBtnState(true);

        } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      images: [],
    },
    validationSchema: NewProductSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
     
            

    
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  const handleChange = (event, newValue) => {
    if (newValue == 0){
      setTab0Color(selectedColor);
      setTab1Color(unSelectedColor);
    }else{
      setTab0Color(unSelectedColor);
      setTab1Color(selectedColor);
    }
    setValue(newValue);
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      setFieldValue(
        'images',
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    },
    [setFieldValue]
  );

  const handleRemoveAll = () => {
    setFieldValue('images', []);
  };

  const handleRemove = (file) => {
    const filteredItems = values.images.filter((_file) => _file !== file);
    setFieldValue('images', filteredItems);
  };

  return (
    <>
       { loading && <LinearProgress color="secondary" /> }
   
    <Stack direction='column' sx={{ ml: 4, mr: 4, mt: 2, mb: 2 }}>
        <Box sx={{  borderColor: 'divider', display:'flex', justifyContent:'space-between', backgroundColor:'#aca2db', borderRadius:5, m:2, p:1}}>
          <Typography sx={{ m:2, ml:4, color:'#ffff' }} variant="h4">
            Image Labelling Tool
          </Typography  >

          <Box component='img' sx={{mr:3}}  src='next.png'></Box>

        </Box>
       
    <Stack direction='row' sx={{ ml: 4, mr: 4, mt: 2, mb: 2, backgroundColor:'#f4f4f4', borderRadius:0.5 }}>
   
      <Stack direction='column' alignItems='left' sx={{ ml: 2, mr: 2, mt: 2, mb: 2, width: '75%' }} >
   
        <Stack direction='row' alignItems='center' spacing={3} sx={{ mt: 2, mb: 2 }} >
          
          <Typography variant="h6" component="div">
            Category
          </Typography>
          <TextField id="outlined-basic" label="Add Category Label"  onChange={setCategory}  value={activeCategory} fullWidth sx={{ maxWidth: 700, mr: 5 }} variant="outlined" ></TextField>
          <Button  variant="contained" onClick={()=>
            addLocalCategory()}>Add Category</Button>
        </Stack>
        <Box  sx={{ display: 'table', backgroundColor:'#ffff'}}>
        
            <Tabs  sx={{  backgroundColor:'#f4f4f4'}} value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab sx={{  backgroundColor:tab0, borderRadius: selectedRadius}} label="Search Image" {...a11yProps(0)} />
              <Tab sx={{  backgroundColor:tab1, borderRadius: selectedRadius}} label="Upload Image" {...a11yProps(1)} />
            </Tabs>
          <TabPanel value={value} index={0}>
            <SearchImage setLoading={setLoading}/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <UploadMultiFile
                setAllCategoryResp={setAllCategoryResp}
                  setLoading={setLoading}
                  showPreview
                  maxSize={3145728}
                  accept="image/*"
                  files={values.images}
                  onDrop={handleDrop}
                  onRemove={handleRemove}
                  onRemoveAll={handleRemoveAll}
                  error={Boolean(touched.images && errors.images)}
                />
                {touched.images && errors.images && (
                  <FormHelperText error sx={{ px: 2 }}>
                    {touched.images && errors.images}
                  </FormHelperText>
                )}
              </Form>
            </FormikProvider>
          </TabPanel>
        </Box>
        <Stack direction="row" justifyContent="space-between" alignItems='center' sx={{m:2}}>
        <Typography variant='p'> A distruptive parent technology to change the way Consumer buy a Product </Typography>
        <Stack direction="row" justifyContent="center" sx={{m:2}}>
         { value === 0 && <Button  disabled={btnDisabled} sx={{ mr: 1.5,  backgroundColor: btnDisabled ? '#e5e8eb' :  '#4291de', color:'#ffff' }} onClick={uploadScrappedImages}>
            Submit
          </Button>
        }
          <Button variant="contained" sx={{ minWidth:180 }} onClick={resumeTraining}>Resume Training</Button>
          </Stack>
        </Stack>
       

      </Stack>
      <Sidebar categories={allCategoryResp} setAllCategoryResp={setAllCategoryResp}/>

      </Stack>
    </Stack>
    </>
  );
}

export default Home;