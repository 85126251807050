import React, { useState } from 'react';
import EditDialog from './EditDialog';
import { Icon } from '@iconify/react';
import { Stack, Box, IconButton, ListItemText, useMediaQuery } from '@mui/material';
import editFill from '@iconify/icons-eva/edit-fill';
import useStore from '../store';

function CategoryItem({category, setAllCategoryResp}) {
    const [open, setOpen] = useState(false);
    //const [cat, setCat] = useState(category);
    const setActiveCategory = useStore((state) => state.setActiveCategory);

    console.log("test"+category);
    const getCategory = (e) =>{
        setActiveCategory(category.split(' (')[0]);
    };

    return (
      <Stack direction='row' alignItems='center'  sx={{justifyContent:'space-between'}}>
        <Stack direction='row' alignItems='center'  sx={{mr:3}}>
        ➤ 
        <ListItemText sx={{ cursor: 'pointer'}} onClick={e => {getCategory(e)}}
        primary={category} />
        </Stack>
        <>
        <IconButton
              edge="start"
              color="inherit"
              onClick={()=>{setOpen(true)}}
              aria-label="close"
            >
              <Icon icon={editFill} />
        </IconButton>
        <EditDialog category={category} open={open} setOpen={setOpen} setAllCategoryResp={setAllCategoryResp} />
        </>
        </Stack>
    );
}

export default CategoryItem;