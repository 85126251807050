import React, { useState, useCallback, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Slider, Box, Card, Link, TextField, Typography, Button, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import Cropper from 'react-easy-crop'
import { Icon } from '@iconify/react';
import useStore from '../store';

function ImageCropper({cropImg, setCroppedPercent}) {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [initialCroppedArea, setInitialCroppedArea] = useState(undefined);
    const [zoom, setZoom] = useState(undefined);

    useEffect(() => {
        const croppedArea = JSON.parse(window.localStorage.getItem('croppedArea'))
        setInitialCroppedArea(croppedArea)
      }, [])
    
      const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        console.log(croppedArea, croppedAreaPixels)
        window.localStorage.setItem('croppedArea', JSON.stringify(croppedArea))
       
        setCroppedPercent(croppedArea);

      }, [])
    

    return (
        <div>
            <div className="App">
      <div className="crop-container">
        <Cropper
          image={cropImg}
          crop={crop}
          zoom={zoom}
          showGrid={false}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          objectFit = "contain"
          initialCroppedAreaPercentages={initialCroppedArea}
        />
      </div>
      <div className="controls">
        <Slider
          value={zoom}
          min={0}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e, zoom) => setZoom(zoom)}
          classes={{ container: 'slider' }}
        />
      </div>
    </div>
        </div>
    );
}

export default ImageCropper;