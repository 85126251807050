import React, { useState, useRef, useEffect } from 'react'
import {Stack} from '@mui/material';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'


import 'react-image-crop/dist/ReactCrop.css'
import useStore from '../store'

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth,
  mediaHeight,
  aspect,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

export default function FreeCropper({imgParam, updateCroppedPercent, croppedValue, restoreImage }) {
  const [imgSrc, setImgSrc] = useState(imgParam)
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState(null)

  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState(undefined)
  const imageDetails = useStore(state => state.imageDetails);
  const [completedCrop, setCompletedCrop] = useState(imageDetails[imgParam]?.imgDimension)


  // function onSelectFile(e) {
  //   if (e.target.files && e.target.files.length > 0) {
  //     setCrop(undefined) // Makes crop preview update between images.
  //     const reader = new FileReader()
  //     reader.addEventListener('load', () =>
  //       setImgSrc(reader.result.toString() || ''),
  //     )
  //     reader.readAsDataURL(e.target.files[0])
  //   }
  // }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined)
    } else if (imgRef.current) {
      const { width, height } = imgRef.current
      setAspect(16 / 9)
      setCrop(centerAspectCrop(width, height, 16 / 9))
      
    }
  }
  useEffect(()=>{

    console.log("imgParam"+imgParam);
    console.log(crop);
    updateCroppedPercent(completedCrop)
  })

  useEffect(()=>{
    if(null == restoreImage){
      setCompletedCrop(null);
      setCrop(null)
    }
   
  }, [restoreImage])


  return (
    <Stack direction='row' >
    
     <div style={{width: '50%'}}>
       
      {Boolean(imgSrc) && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
          
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            // onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      </div>
      <div  style={{width: '50%'}}>
        {Boolean(completedCrop) && (
          <canvas
            ref={previewCanvasRef}
            style={{
              border: '1px solid black',
              objectFit: 'contain',
              width: completedCrop.width,
              height: completedCrop.height,
            }}
          />
        )}
      </div>
    </Stack>
  )
}
