const createConfigSlice = (set, get) => ({
    activeCategory: '',
    activeTab: 0,
    imageDetails: {},
    allCategories: [],
    btnDisabled: true,

    setBtnState:(payload)=>{
        set({
            btnDisabled: payload,
        });
    },

    resetImageDetails:()=>{
        set({
            imageDetails: {},
        });
    },
   
    updateSearchresults: (payload) => {
        const imageDetails = get().imageDetails;
        const imgDimension =  payload.croppedArea;
        const previousProps=  imageDetails[payload.imgSrc]
        imageDetails[payload.imgSrc] = {...previousProps, imgDimension};
        set({
            imageDetails: imageDetails,
        });
    },
    updateImageSelection: (payload) => {
        const imageDetails = get().imageDetails;
        const isActive =  payload?.isSelected;
        const previousProps=  imageDetails[payload.imgSrc]
        imageDetails[payload.imgSrc] = {...previousProps ,isActive};
        set({
            imageDetails: imageDetails,
        });


    },
    setSearchResults:(payload) =>{
        let croppedImageDetails = {};
        payload.forEach(element => {
            croppedImageDetails[element] = null
        });
        set({
            imageDetails: croppedImageDetails,
               });
               //console.log(get().imageDetails);
       
    },
    setActiveTab:(payload) =>{
        set({
            activeTab: payload,
               });
       
    },
    setActiveCategory:(payload) =>{
        set({
            activeCategory: payload,
               });
       
    },
    addCategory:(payload) =>{
        const tempCategories = get().allCategories;
        tempCategories?.push(payload);
        set({
            allCategories: tempCategories,
               });
       
    },

 
    
  });
  
  export default createConfigSlice;
  