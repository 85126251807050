import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Icon } from '@iconify/react';
import closeFill from "@iconify/icons-eva/close-fill";
import MIconButton from './MIconButton';
import { useSnackbar } from "notistack";
import useStore from '../store';


import { Dialog, Stack, Button, TextField} from '@mui/material';

function EditDialog({category, open, setOpen, setAllCategoryResp}) {
    const categoryName = category.split(' (')[0];
    const {enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [updatedCat, setUpdatedCat] = useState('');

    const updateCategoryName = async () => {
        try{
          const tempCategories = [];
          const res = await axios({
            method: 'PUT',
            url: process.env.REACT_APP_baseURL + "updateCategory",
            headers: {
              "Content-Type": "application/json",
              'oldcategory': categoryName,
              'newcategory': updatedCat,
            }
          });

          if( typeof res?.data === 'string'){
            alert(res?.data);
            return;
          }else{
          console.log(res);
          const categoriesResponse = res?.data;

          Object.keys(categoriesResponse).map((category, i) => {
            console.log(category);
            tempCategories.push(category + ' (' + categoriesResponse[category] + ')')
          });
          console.log(tempCategories);
          setAllCategoryResp(tempCategories)

          setOpen(false);
          enqueueSnackbar("Updated Category Name", {
            variant: "success",
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          });
        }
  
        }catch(err){
          console.log(err);
          enqueueSnackbar("Failed to update name", {
            variant: "error",
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          });
          setOpen(false);
        }
    }

    const handleClose = (event, reason) => {
        setOpen(false);
    }

    return (
        <Dialog open={open} onClose={handleClose} sx={{borderRadius:0.1}} >
        <Stack spacing={5} sx={{ alignItems: 'center', m: 10,  }}>
            <h4>Selected Category</h4>
            {categoryName}
        <TextField  id="outlined-basic" label="Updated Name" value={updatedCat} onChange={(e)=> setUpdatedCat(e.target.value)} variant="outlined" />
        <Button variant='contained' onClick={updateCategoryName}>SAVE</Button>
        </Stack>
    </Dialog>
    );
}

export default EditDialog;