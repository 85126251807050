import React from "react";
import { SelectableGroup } from "react-selectable-fast";
import { Stack, Box, Card, Divider, TextField, Typography, Grid, Button, ListItemText, useMediaQuery } from '@mui/material';

import ItemGroup from "./ItemGroup";

import "./styles.css";

export default function App(props) {
  const handleSelectedItemUnmount = () => {
    console.log("item unmount");
  };
  const handleSelecting = (items) => {
    console.log("selecting:", items);
  };

  const handleSelectionFinish = (items) => {
    console.log("finish selecting:", items);
  };
  const handleSelectionClear = () => {
    console.log("selection cleared");
  };
  return (
    <SelectableGroup
    
      enableDeselect
      //tolerance={this.state.tolerance}
      //globalMouse={this.state.isGlobal}
      allowClickWithoutSelected={false}
      duringSelection={handleSelecting}
      onSelectionClear={handleSelectionClear}
      onSelectionFinish={handleSelectionFinish}
    
    >
      <ItemGroup items={[1, 2, 3, 4, 6,7,8,9,10,11,12]} />
     
    </SelectableGroup>
  );
}
