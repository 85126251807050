import React from 'react';
import { Stack, Box, Card, Link, TextField, Typography, Button, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';

function SImage(props) {
    const { selectableRef, isSelected, isSelecting } = props;
    return (       
          <div
            ref={selectableRef}
          >
              {isSelected ? <h3>dfgdf</h3> : ''}
              <Box component="img" src={props.img} ></Box>
          </div>
    );
}

export default SImage;