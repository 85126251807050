import React, { useState } from 'react';
import { Stack, Box, Card, Link, TextField, Typography, Button, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import { render } from 'react-dom';
import SImage from './SImage';
import { SelectableGroup } from 'react-selectable-fast';
import { createSelectable, SelectAll, DeselectAll } from 'react-selectable-fast'
import "./styles.css";
import ItemGroup from './ItemGroup';
import Test from './Test';
import axios from 'axios';
import useStore from '../store';
const SelectableComponent = createSelectable(SImage);



function SearchImage({setLoading}) {
    const [selectedKeys, setSelectedKeys] = useState('');
    const [images, setImages] = useState(5);
    const setSearchResults = useStore(state => state.setSearchResults);
    const activeCategory = useStore(state => state.activeCategory);
    const setBtnState = useStore(state => state.setBtnState);
    
    const handleSelecting = (items) => {
        console.log("selecting:", items);
      };
    
      const handleSelectionFinish = (items) => {
        console.log("finished selecting:", items);
        setSelectedKeys(items);
      };
      const handleSelectionClear = () => {
        console.log("selection cleared");
      };

    const handleSelection  = (e) => {
        setSelectedKeys(e.target.value);
    };

    const handleImagesSelection  = (e) => {
      setImages(e.target.value);
  };

     const  searchImages = async () => {
      try {

        if (activeCategory === "" ){
          window.alert("Select Category against which images will get uploaded");
          return;
        }
        if (selectedKeys === "" ){
          window.alert("Keywords can not be empty");
          return;
        }
        setLoading(true);
          //setSubmitting(false);
          const serverUrl = process.env.REACT_APP_baseURL;
          //enqueueSnackbar(!isEdit ? 'Create success' : 'Update success', { variant: 'success' });
          //navigate(PATH_DASHBOARD.eCommerce.list);
          let arrayOfYourFiles=[]
          // create formData object
          let formData = new FormData();
          formData.append("keywords", selectedKeys);
          console.log(formData);
          let res = await axios({
            method: "GET",
            url: serverUrl + "search",
            data: {
              'keywords': selectedKeys,
            },
            headers: {
              'Access-Control-Allow-Origin': '*',
              "Content-Type": "application/json",
               'keywords': selectedKeys,
               'images': images
            }
          });
          setLoading(false);
          console.log(res);
          let imageResults = [];
          Object.values(res?.data).map((url, i) => {
            console.log(url);
            imageResults.push(url)
          });
          setBtnState(false);
          setSearchResults(imageResults);
      } catch (error) {
        console.error(error);
        setBtnState(false);
        setLoading(false);
        window.alert("Some error occur while fetching images");
        //setSubmitting(false);
        //setErrors(error);
      }
    };

    return (
        <div>
        <Stack direction='row'>
         
         <TextField id="outlined-basic" value={selectedKeys} onChange={handleSelection} fullWidth  label="Search" variant="outlined" sx={{maxWidth: 500, mr: 5}} />
         <TextField  value={images} onChange={handleImagesSelection}   label="No of Images" variant="outlined" sx={{ mr: 5}} />

         <Button variant="contained" onClick={searchImages}>Search</Button>

         </Stack>
       
        <Test/>
      </div>
    
    );
}

export default SearchImage;