import Home from "./Home";
import ThemeConfig from './theme';

import NotistackProvider from './components/NotistackProvider';

function App() {
  return (
    <ThemeConfig themePref="light">
      <NotistackProvider>
      <Home/>
      </NotistackProvider>
    </ThemeConfig>
  );
}

export default App;
